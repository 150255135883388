import React, { useState } from "react"
import { connect } from "react-redux"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import ResponsiveSpacing from "../components/responsive-spacing"
import Layout from "../components/layout"
import Inner from "../components/inner"
import Garnish from "../components/garnish"
import SEO from "../components/seo"
import BottomColorNav from "../components/bottom-color-nav"
import Center from "../components/center"
import SVG from "../components/svg"

const QuestionStyled = styled.div`
  color: ${(p) => p.theme.Blue};
  letter-spacing: 2px;
  max-width: 800px;
  padding: 20px 0;
  cursor: pointer;
  margin: 0px auto;
  border-bottom: 1px solid ${(p) => p.theme.Grey};

  .top:hover .text {
    color: ${(p) => p.theme.Blue};
  }

  .text {
    color: ${(p) => p.theme.DarkBlue};
    font-size: 1.4em;
    line-height: 1.6em;
    margin: 0;
    min-height: 26px;
    letter-spacing: 2px;
    font-weight: bold;
    padding-left: 46px;
  }

  a {
    color: ${(p) => p.theme.DarkBlue};
    font-weight: bold;
  }

  .answer {
    color: ${(p) => p.theme.DarkBlue};
    font-size: 1.4em;
    margin: 8px 0;
    line-height: 1.6em;
    overflow: hidden;
    padding-left: 45px;
    transition: max-height 600ms;
    height: auto;
    max-height: ${(p) => (p.open ? "600px" : "0px")};
  }

  .svg {
    color: ${(p) => p.theme.DarkBlue};
    height: 30px;
    width: 30px;
    transition: transform 300ms;
    float: left;
    margin-top: 2px;
    margin-right: 16px;
  }
  .svg.down {
    transform: rotate(90deg);
  }
`

const Question = ({ q }) => {
  const [open, setOpen] = useState(false)
  return (
    <QuestionStyled open={open}>
      <div className="top" onClick={(e) => setOpen(!open)}>
        <SVG name="Arrow" className={`svg ${open ? "down" : ""}`} />
        <div
          className="text"
          dangerouslySetInnerHTML={{
            __html: q.text,
          }}
        />
      </div>
      <div
        className="answer"
        dangerouslySetInnerHTML={{
          __html: q.answer,
        }}
      />
    </QuestionStyled>
  )
}

const SectionStyled = styled.div`
  h3 {
    color: ${(p) => p.theme.Blue};
    text-transform: uppercase;
    font-size: 2.2em;
    max-width: 800px;
    margin: 80px auto 0 auto;
    letter-spacing: 2px;
  }
`

const Section = ({ fields }) => {
  const { questions, heading } = fields
  return (
    <SectionStyled>
      <h3>{heading}</h3>
      {questions.map((q, i) => (
        <Question q={q} key={i} />
      ))}
    </SectionStyled>
  )
}

const FAQStyled = styled.div`
  margin: 123px auto 150px auto;
  font-size: 10px;
  h2 {
    text-align: center;
    color: ${(props) => props.theme.DarkBlue};
    margin: 40px 0 0 0;
    font-size: 9.2em;
    font-weight: 800;
    text-transform: uppercase;
    font-family: "interstate-compressed";
    @media (max-width: 820px) {
      line-height: 0.9em;
      font-size: 5em;
      padding: 0 19px;
    }
  }
  @media (max-width: 768px) {
    padding: 0 10px;
  }
`

const FAQ = ({ fields }) => {
  const { page_headline, sections, wave_color, garnishes } = fields

  return (
    <FAQStyled>
      <ResponsiveSpacing>
        <Inner maxWidth="1100px">
          <div className="heading">
            <h1 style={{ display: "none" }}>Frequently Asked Questions</h1>
            <h2 tabIndex="0">{page_headline}</h2>
            <Center margin="30px 0 70px 0">
              <SVG name="SWave" color={wave_color} />
            </Center>
            <Garnish
              garnish={garnishes[0]}
              pSpeed={-0.1}
              top={500}
              rotation={0}
              rSpeed={0}
              pos={"left"}
            />
            <Garnish
              garnish={garnishes[1]}
              pSpeed={-0.1}
              top={1000}
              rotation={0}
              rSpeed={-0}
              pos={"right"}
            />{" "}
            {sections.map((s, i) => {
              return <Section fields={s} />
            })}
          </div>
        </Inner>
      </ResponsiveSpacing>
    </FAQStyled>
  )
}

const ConnectedFAQ = connect((state) => state)(FAQ)

const FAQPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allWordpressPage(filter: { slug: { eq: "faq-page" } }) {
        edges {
          node {
            acf {
              page_headline
              wave_color
              sections {
                heading
                questions {
                  text
                  answer
                }
              }
              search_title
              search_description
              share_headline
              share_comment
              share_image {
                source_url
              }
              garnishes {
                image {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 200, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const fields = data.allWordpressPage.edges[0].node.acf
  const {
    search_title,
    share_headline,
    search_description,
    share_comment,
    share_image,
  } = fields

  return (
    <Layout>
      <SEO
        title={search_title}
        description={search_description}
        headline={share_headline}
        share_comment={share_comment}
        image={share_image}
      />
      <ConnectedFAQ fields={fields} />
      <BottomColorNav />
    </Layout>
  )
}

export default FAQPage
